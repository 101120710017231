'use client';
import { CartIcon, CurationsIcon, EyeIcon, HeartIcon } from '@c/icons';
import { SvgIconProps } from '@c/icons/types';
import WishlistAction from '@c/toasts/WishlistAction';
import { PublicUserDocument } from '@models/user';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Chip from '@ui/Chip';
import SafeImage from '@ui/SafeImage';
import { getItem, logEvent } from '@util/analytics';
import { getBidsCountByProductId } from '@util/firestore/bid/bid.service';
import {
  addProductToFavorites,
  removeProductFromFavorites,
} from '@util/firestore/products';
import {
  trackUserInteraction,
  trackUserSearch,
} from '@util/firestore/recommendations';
import useCountdown from '@util/hooks/useCountdown';
import { formatCurrency, getCDNUrl } from '@util/index';
import { getProductSlug } from '@util/urlHelpers';
import { useAttributionContext } from 'context/AttributionContext';
import { useAuth } from 'context/AuthContext';
import { useSearch } from 'context/SearchContext';
import { useToastContext } from 'context/ToastContext';
import QuickViewModal from 'features/shop/components/QuickViewModal';
import { OrderItemDocument } from 'models/order';
import { ProductDocument } from 'models/product';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useShoppingCart } from 'context/ShoppingCartContext';
import { getCartItemFromProductDocument } from '@util/index';
import { Truck, WhiteTruck } from './MXListProductCard';
import useLogWhenInView from '@util/hooks/useLogWhenInView';
import { MotionDiv } from 'motion';

const MXProductCard = ({
  product,
  // seller,
  list_id,
  list_name,
  attribution,
  bgColor,
  priority,
}: {
  product: ProductDocument;
  seller?: PublicUserDocument;
  list_id?: string;
  list_name?: string;
  attribution: OrderItemDocument['attribution'];
  bgColor?: string;
  priority?: boolean;
}) => {
  const [quickViewOpen, setQuickViewOpen] = useState(false);
  const { increaseCartQty, canAddProductToCart, setCartOpen } =
    useShoppingCart();
  const { setAttribution, getAttribution } = useAttributionContext();
  const pathname = usePathname();
  const { userDoc, observeProduct } = useAuth();
  const { filters } = useSearch();
  const router = useRouter();

  const handleAddToCart = () => {
    if (userDoc) {
      if (canAddProductToCart(product.id)) {
        const attribution = getAttribution(product.id);
        const cartItem = getCartItemFromProductDocument(
          product,
          null,
          attribution
        );

        increaseCartQty(cartItem);
        setCartOpen(true);
      } else {
        setCartOpen(true);
      }
    } else {
      router.push(`/login?redirect=${encodeURI(pathname ?? '')}`);
    }
  };

  const quickViewButtonControls = useAnimation();

  const ref = useLogWhenInView(() => {
    if (
      userDoc?.uid &&
      ['gear_for_you', 'parts_for_you'].includes(list_id ?? '')
    ) {
      observeProduct(product.id);
    }
  });

  useEffect(() => {
    quickViewButtonControls.start({ opacity: 0 });
  }, [quickViewButtonControls]);

  return (
    <MotionDiv
      ref={ref}
      className={`group relative mx-auto flex h-[35rem] w-[22rem] flex-col overflow-hidden rounded-brand sm:mx-0 sm:h-[43rem] sm:hover:shadow-md lg:w-[29rem] ${
        bgColor ? bgColor : ''
      }`}
      onHoverStart={() => {
        quickViewButtonControls.start({
          opacity: 1,
          transition: { duration: 0.2, ease: 'linear' },
        });
      }}
      onHoverEnd={() => {
        quickViewButtonControls.start({
          opacity: 0,
          transition: { duration: 0.2, ease: 'linear' },
          x: 0,
        });
      }}
    >
      <Link
        href={getProductSlug(product)}
        className="flex h-full flex-col"
        onClick={() => {
          setAttribution(product.id, attribution);
          logEvent('select_item', {
            items: [getItem(product)],
            item_list_id: list_id ?? pathname,
            item_list_name: list_name ?? pathname,
          });
          if (pathname?.includes('search') || pathname?.includes('product')) {
            if (userDoc)
              trackUserInteraction({
                uid: userDoc.uid,
                pid: product.id,
                interaction: 'search_clicked',
              });
            if (filters?.term) {
              trackUserSearch({
                ...filters,
                product_clicked: product.id,
              });
            }
          }
        }}
      >
        <div className="flex h-full flex-col">
          <ProductPicture product={product} priority={priority} />
          <div className="flex flex-grow flex-col justify-between space-y-2 p-[0.8rem]">
            <div className="space-y-2">
              <ProductTitle product={product} />

              <div className="flex w-full justify-between">
                <Price product={product} />

                {!product.is_auction && product.condition && (
                  <span
                    className={`line-clamp-1 w-[6rem] rounded-lg bg-[#EEE] p-px text-center text-[1.6rem] font-medium text-black`}
                  >
                    {product.condition}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </Link>

      {product.is_flat_rate &&
      product.shipping_costs?.every(({ cost }) => cost === 0) &&
      !product.is_digital ? (
        <div className="absolute left-0 top-0 rounded-br-xl bg-black px-4 py-2">
          <div className="flex items-center gap-[0.4rem]">
            <WhiteTruck className={'h-9 w-9'} />
            <span className="text-[1.4rem] text-white">Free Shipping</span>
          </div>
        </div>
      ) : product.is_flat_rate &&
        product.shipping_costs?.find((c) => c.code === 'US' && c.cost === 0) ? (
        <div className="absolute left-0 top-0 rounded-br-xl bg-black px-4 py-2">
          <div className="flex items-center gap-[0.4rem]">
            <WhiteTruck className={'h-9 w-9'} />
            <span className="text-[1.4rem] text-white">Free US Shipping</span>
          </div>
        </div>
      ) : null}

      <div className="absolute right-[1rem] top-[1rem] flex w-full justify-end sm:right-[1rem]">
        <FavoriteButton
          product={product}
          initialCount={product.favorite_count}
        />
      </div>

      <QuickViewModal
        product={product}
        isOpen={quickViewOpen}
        dismiss={() => setQuickViewOpen(false)}
      />

      {pathname !== '/my-cart' ? (
        <>
          {product.category !== 'Dirt Bikes' && !product.is_auction && (
            <div className="absolute top-[23rem] hidden w-full justify-center sm:flex">
              <QuickViewButton
                product={product}
                setQuickViewOpen={setQuickViewOpen}
                attribution={attribution}
                setAttribution={setAttribution}
                quickViewButtonControls={quickViewButtonControls}
              />
            </div>
          )}
        </>
      ) : (
        <div className="absolute right-[1rem] top-[15rem] flex w-full cursor-pointer items-end justify-end lg:right-[2rem] lg:top-[23rem]">
          <AddToCartButton handleAddToCart={handleAddToCart} />
        </div>
      )}
    </MotionDiv>
  );
};

const QuickViewButton = ({
  product,
  setQuickViewOpen,
  attribution,
  setAttribution,
  quickViewButtonControls,
}: {
  product: ProductDocument;
  setQuickViewOpen: (open: boolean) => void;
  attribution: OrderItemDocument['attribution'];
  setAttribution: (
    id: string,
    attribution: OrderItemDocument['attribution']
  ) => void;
  quickViewButtonControls: any;
}) => {
  return (
    <MotionDiv
      className="flex cursor-pointer items-center justify-center gap-x-2 whitespace-nowrap rounded-full bg-brand-black px-8 py-4"
      initial={{ opacity: 0 }}
      animate={quickViewButtonControls}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setQuickViewOpen(true);
        setAttribution(product.id, attribution);
        logEvent('open_quick_view', {
          items: [getItem(product)],
        });
      }}
    >
      <div className="flex items-center gap-x-2">
        <EyeIcon className={'h-10 w-10 text-white'} />

        <MotionDiv initial={{ opacity: 0 }} animate={quickViewButtonControls}>
          <span className="font-semibold text-white">Quick View</span>
        </MotionDiv>
      </div>
    </MotionDiv>
  );
};

const AddToCartButton = ({
  handleAddToCart,
}: {
  handleAddToCart: () => void;
}) => {
  const addToCartButtonControls = useAnimation();

  useEffect(() => {
    addToCartButtonControls.start({ opacity: 0, display: 'none', x: -20 });
  }, [addToCartButtonControls]);

  return (
    <MotionDiv
      className="z-50 flex w-[20rem] items-center justify-center gap-x-2 rounded-full bg-brand-secondary p-4"
      initial={{ height: '4.5rem', width: '4.5rem' }}
      whileHover={{
        width: '23rem',
        transition: { duration: 0.3, ease: 'linear' },
      }}
      onHoverStart={() =>
        addToCartButtonControls.start({
          opacity: 1,
          display: 'block',
          x: 0,
          transition: { duration: 0.5, ease: 'linear' },
        })
      }
      onHoverEnd={() =>
        addToCartButtonControls.start({
          opacity: 0,
          display: 'none',
          x: -20,
          transition: { duration: 0.5, ease: 'linear' },
        })
      }
      onClick={handleAddToCart}
    >
      <CartIcon className={'h-10 w-10 text-white'} />

      <MotionDiv
        initial={{ opacity: 0, x: -20, display: 'none' }}
        animate={addToCartButtonControls}
      >
        <span className="whitespace-nowrap font-semibold text-white">
          Add to Cart
        </span>
      </MotionDiv>
    </MotionDiv>
  );
};

const ProductTitle = ({ product }: { product: ProductDocument }) => {
  const { userDoc } = useAuth();
  const { data: bidCount } = useQuery({
    queryKey: ['bidCount', product.id],
    queryFn: () => getBidsCountByProductId(product.id),
    enabled: !!product.is_auction,
  });

  return (
    <div className="space-y-1">
      <div className="flex w-full items-center justify-between">
        {product.is_auction && (
          <div className="flex w-full items-center justify-between gap-[0.4rem] gap-x-2">
            <Chip text="Auction" color="gray" />

            <span className="whitespace-nowrap text-[1.4rem] font-medium text-gray-500">
              {bidCount !== undefined ? `${bidCount} Bids` : ''}
            </span>
          </div>
        )}
      </div>

      <span
        className={`line-clamp-2 h-[4.4rem] overflow-hidden text-ellipsis text-[1.6rem] font-bold sm:font-semibold`}
        // using styles to force line-clamp-2 to work
        style={{
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
        }}
      >
        {product.title}
      </span>

      {!product.is_auction && (
        <>
          {product.size?.letter ||
          product.size?.number ||
          !!product.variations?.length ? (
            <span className="line-clamp-1 text-[1.6rem] font-medium text-brand-dark-gray">
              {product.size?.letter ? `Size: ${product.size?.letter}` : ''}{' '}
              {product.size?.number
                ? `${product.size?.letter ? '/ ' : ''} Size: ${
                    product.size?.number
                  }`
                : ''}
              {!!product.variations?.length && product.variations.length > 1
                ? 'Multiple sizes'
                : ''}
            </span>
          ) : (
            <div className="text-elipsis line-clamp-1 h-[2.4rem] text-[1.6rem] font-medium uppercase text-brand-dark-gray sm:pr-10 sm:uppercase">
              {/* nothing here but we want the space so cards are aligned */}
              {userDoc?.roles?.admin
                ? product.category1 === 'Boots' ||
                  product.category1 === 'Helmets'
                  ? product.category1
                  : product.category3
                  ? product.category3
                  : product.category2
                  ? product.category2
                  : product.location_display
                  ? product.location_display
                  : product.category1
                  ? product.category1
                  : product.category
                : ''}
            </div>
          )}
        </>
      )}
    </div>
  );
};

const ProductInfo = ({ product }: { product: ProductDocument }) => {
  return (
    <div className="space-y-1">
      {!product.is_auction && !product.categories.includes('Riding Gear') && (
        <span className="line-clamp-1 text-[1.6rem] font-medium text-brand-dark-gray">
          {product.size?.letter ? `Size: ${product.size?.letter}` : ''}{' '}
          {product.size?.number
            ? `${product.size?.letter ? '/ ' : ''} Size: ${
                product.size?.number
              }`
            : ''}
          {!!product.variations?.length && product.variations.length > 1
            ? 'Multiple sizes'
            : ''}
        </span>
      )}
    </div>
  );
};

const ProductAuctionCountdown = ({ product }: { product: ProductDocument }) => {
  const isStarted = product.start_time! < Date.now();
  const timeRemaining = useCountdown(
    product.start_time!,
    product.end_time!,
    isStarted ? 'end' : 'start'
  );
  return (
    <div className="absolute bottom-0 w-full p-4">
      <div
        className={`w-full rounded-2xl ${
          isStarted ? 'bg-[#1D7A1E]' : 'bg-brand-secondary'
        } p-[0.8rem] text-center text-white`}
      >
        <span
          suppressHydrationWarning
          className="block w-full text-[1.2rem] font-medium sm:text-[1.6rem]"
        >
          {isStarted ? 'Ends in ' : 'Starts in '}
          {timeRemaining.days > 0 && `${timeRemaining.days}d `}
          {timeRemaining.hours}h {timeRemaining.minutes}m{' '}
          {timeRemaining.seconds}s
        </span>
      </div>
    </div>
  );
};

const Price = ({ product }: { product: ProductDocument }) => {
  let fullPrice = product.price;

  const textColor = () => {
    if (product.is_auction) return 'text-green-600';
    if (product.on_sale) return 'text-brand-secondary';
    if (!product.on_sale && !product.is_auction) return 'text-brand-primary';
  };

  return (
    <div className="mt-1 flex gap-[0.4rem]">
      <span
        className={`text-[1.8rem] font-bold text-brand-${
          product.on_sale ? 'secondary' : 'primary'
        }`}
      >
        {formatCurrency(fullPrice)}
      </span>
    </div>
  );
};

const SoldOutOverlay = () => (
  <div className="absolute inset-0 m-auto flex h-[5rem] items-center justify-center">
    <span className="absolute z-10 text-[2.2rem] font-semibold tracking-wider text-white">
      SOLD
    </span>
    <div className="absolute h-full w-full bg-brand-secondary opacity-80" />
  </div>
);

const ProductPicture = ({
  product,
  priority,
}: {
  product: ProductDocument;
  priority?: boolean;
}) => {
  return (
    <>
      <div className="relative h-[22rem] w-[22rem] overflow-hidden sm:h-[35rem] sm:w-full">
        <SafeImage
          alt={product.title}
          src={product.thumbnail}
          fallbackSrc={product.images[0].download_url}
          fill
          priority={priority}
          classes="object-cover h-full transition-transform duration-200 ease-in-out group-hover:scale-105"
        />

        {product.on_sale && (
          <DiscountBadge
            discount={Math.round(
              ((product.og_price - product.price) / product.og_price) * 100
            )}
          />
        )}

        {product.from_recommend && (
          <div className="absolute  left-[1rem] top-[0.8rem] flex w-[4rem] gap-4 overflow-x-hidden whitespace-nowrap rounded-[0.4rem] bg-brand-secondary  px-4 py-[0.8rem]  font-semibold text-white transition-all hover:w-[10rem] ">
            <span className="grow">
              <CurationsIcon width={20} height={20} />
            </span>
            For you
          </div>
        )}

        {product.out_of_stock && !product.is_auction && <SoldOutOverlay />}

        {product.is_auction &&
          product.end_time! > Date.now() &&
          !product.is_draft && <ProductAuctionCountdown product={product} />}
      </div>
    </>
  );
};

export const FavoriteButton = ({
  product,
  initialCount = 0,
}: {
  product: ProductDocument;
  initialCount?: number;
}) => {
  const likedProps: SvgIconProps = {
    pathProps: {
      stroke: 'none',
      fill: '#FF4242',
      className: 'hover:none',
    },
  };
  const defaultProps: SvgIconProps = {
    pathProps: {
      stroke: 'currentColor',
      fill: 'none',
    },
  };
  const [isLiked, setLiked] = useState(false);
  const [favoriteCount, setFavoriteCount] = useState(initialCount);

  const { userDoc } = useAuth();
  const router = useRouter();
  const pathname = usePathname();
  const queryClient = useQueryClient();
  const { showToast } = useToastContext();

  useEffect(() => {
    if (userDoc) {
      const liked = userDoc.favorites?.includes(product.id);
      setLiked(!!liked);
    }
  }, [product.id, userDoc]);

  const props = isLiked ? likedProps : defaultProps;

  const onButtonClick = () => {
    setLiked(!isLiked);
    if (userDoc) {
      if (isLiked) {
        removeProductFromFavorites(userDoc.uid, product.id);
        setFavoriteCount(favoriteCount - 1);
        trackUserInteraction({
          uid: userDoc.uid,
          pid: product.id,
          interaction: 'unfavorite',
        });
      } else {
        addProductToFavorites(userDoc.uid, product.id);
        setFavoriteCount(favoriteCount + 1);
        trackUserInteraction({
          uid: userDoc.uid,
          pid: product.id,
          interaction: 'favorite',
        });
      }
      queryClient.invalidateQueries({
        queryKey: ['authUser'],
      });
      queryClient.invalidateQueries({
        queryKey: ['favorites', userDoc?.uid],
      });
      logEvent(
        'add_to_wishlist',
        {
          currency: 'USD',
          items: [getItem(product)],
        },
        userDoc?.uid
      );
      showToast(<WishlistAction removed={isLiked} />);
    }
    // router.push({
    //   pathname: '/login',
    //   query: { redirect: pathname },
    // });
    else router.push(`/login?redirect=${encodeURI(pathname ?? '/')}`);
  };
  return (
    <button
      aria-label="favorite"
      className="absolute z-10 h-16 w-16 rounded-full bg-brand-white"
      onClick={(e) => {
        e.preventDefault();
        onButtonClick();
      }}
    >
      {initialCount !== undefined ? (
        <span className="flex gap-[0.8rem] px-4">
          <HeartIcon className="h-full w-full" {...props} />
        </span>
      ) : (
        <HeartIcon className="h-full w-full" {...props} />
      )}
    </button>
  );
};

const DiscountBadge = ({ discount }: { discount: number }) => {
  return (
    <div className="absolute bottom-[0.8rem] left-[1rem] flex items-center justify-center rounded-[1.5rem] bg-brand-secondary px-[1.6rem] py-[0.8rem]">
      <span className="text-[1.5rem] font-semibold text-white">
        {discount | 0}% Off
      </span>
    </div>
  );
};
export default MXProductCard;
